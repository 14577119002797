.slider__item {
    max-width: 100%;
    height: 750px;
    background-color: white;
    margin-bottom: -60px;
}

.slider__item-01 {
    background: 
    url("../assets/all-image/slider/finalTemplate.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; 
}

.booking__form-page{
    margin-top: 550px;
    margin-left: 1000px;
    padding: 10px 14px;
    width: 200px;
    border-radius: 30px;
    font-weight: 600;
    font-family: sans-serif;
    background-color: #040404;
    color: white;
}

.booking__form-page:hover{
    background-color:#de3513;
    color: white;
}

.screen__button {
    margin-left: 1100px;
}

.screen__button button {
    margin-top: 500px;
}

.hero__slider {
    overflow-x: hidden;
}

.slider__content {
    padding-top: 100px;
}

.slider__content h1 {
    font-size: 3.2rem;
    font-weight: 600;
}

.reserve__btn {
    background: #fff !important;
    color: #6b1900 !important;
    border: none;
    outline: none;
}

.reserve__btn a {
    text-decoration: none;
    font-weight: 600;
    color: #6b1900;
}

.reserve__btn:hover {
    background: #6b1900;
    color: #fff;
}

/*-----------------------------------this is the form desing code------------------------------------------*/
.hero__slider {
    overflow-x: hidden;
}
.container__form{
    width: 90%;
    max-width: 250;
    background: rgba(122, 120, 120, 0.314);
    padding: 18px;
    margin: 0 50px;
    margin-top: 300px;
    border-radius: 1px;
    display: none;
}

.container__form form .title{
    font-size: 39px;
    font-weight: 600;
    margin: 6px 0;
    color: #fff;
    text-align: center;
}

.container__form form .feilds{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

form .feilds .input-feild{
    display: flex;
    width: calc(100% / 4 - 10px);
    flex-direction: column;
    margin: 4px 0;
}

.input-feild input{
    outline: none;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 400;
    color: #333;
    border: 1px solid #aaa;
    height: 42px;
    margin: 8px 0;
    font-size: 18px;
}

.input-feild input:is(:focus, :valid) {
    box-shadow: 0 3px 6px rgb(0, 0, 0, 0.1);
}

.input-feild input[type="date"] {
    color: #707070;
    font-family: bold;
    font-size: 18px;
    color: #090909;
}

.input-feild input[type="date"]:valid{
    color: #333;
}

.input-feild .cars{
    outline: none;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 400;
    color: #333;
    border: 1px solid #aaa;
    height: 42px;
    margin: 8px 0;
    font-family: bold;
    font-size: 18px;
}

.input-feild-btn button {
    background-color: #040404;
    color: #fff;
    border-radius: 1px;
    width: 271px;
    height: 45px;
    text-align: center;
    border: none;
}
.input-feild-btn button:hover{
    background-color: #9b1900;
    color: #fff;
}

::placeholder{
    color: black;
    font-family: bold;
    font-size: 18px;
}

.all__value-data h6 {
    color: #fff;
}
span{
    color: #fff;
}

li{
    color: #fff;
}

.mobileScreen__button {
    display: none;
}


/* {------------------------------------------------ Mobile Screen Code ----------------------------------------------} */

@media only screen and (min-width: 300px) and (max-width: 767px)  {
    .hero__slider {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        margin-top: -120px;
   }

    .slider__item {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        margin-top: 60px;
    }

    .slider__item-01{
        background-image: url("../assets/all-image/slider/finalTemplateMobile.jpeg");
        background-position: center, center;
        background-repeat: no-repeat;
        width:  100px;
        height: 100px;
        background-size:cover;
        
    }

    .slider__content {
        margin-top: -50px;
    }
    
    .container__form {
        display: none;
    }

    .container__form form .title {
        font-size: 20px;
    }

    .form .feilds .input-feild {
        display: flex;
        width: calc(100% / 2 - 10px);
        flex-direction: column;
        margin: 2px 0px;
    }

    .input-feild-btn button {
        background-color: #040404;
        color: #fff;
        border-radius: 1px;
        width: 271px;
        height: 45px;
        text-align: center;
        border: none;
        margin-left: 10px;
    }
}

@media only screen and (min-width: 300px) and (max-width: 767px)  {
    .slider__item {
        height: 400px;
        width: 500px;
    }

    .slider__item-01{
        overflow-x: hidden;
        height: 110%;
    }

    .container__form{
        margin-top: 50px;
        margin-left: 20px;
    }

    .container__form form .title {
        font-size: 20px;
    }

    .form .feilds .input-feild{
        display: flex;
        width: calc(110% / 1 - 10px);
        flex-direction: column;
        margin: 2px 0px;
        border-radius: 15px;
    }

    .form .feilds .input-feild {
        border-color: #9b1900;
    }

    .input-feild-btn button {
        background-color: #040404;
        color: #fff;
        border-radius: 1px;
        width: 235px;
        height: 45px;
        text-align: center;
        border: none;
        margin-left: -3px;
    }

    .input-feild-btn button:hover {
        background-color: #9b1900;
    }

    form .feilds .input-feild1 {
    display: flex;
    width: calc(100% / 1 - 10px);
    flex-direction: column;
    margin: 4px 0;
    margin-left: 5px;
}

::placeholder {
   text-align: left; 
   color: rgb(239, 6, 6);
}

.mobileScreen__button {
    display: flex;
    text-align: right;
    margin-top: 280px;
    justify-content: center;
    padding: 1px;
}

.btn__mobile {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    width: 150px;
    margin: 5px 0;
    height: 40px;
    border-color: #fe0707;
    border-radius: 50px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    font-family: bold;
    margin-top: 20px;
}

.btn__mobile:hover{
    background-color: #de3513;
    color: #fff;
 }

 section .text-center-service{
    margin-top: -20px;
    text-align: center;
    justify-content: center;
}

section .section__title-service {
    margin-top: -50px;
    color: rgb(133, 13, 13);
    font-family: sans-serif;
    font-weight: 900; 
}
.booking__form-page{
    display: none;
    }
}


@media only screen and (min-width:668px) and (max-width: 1023px) {
    .slider__item {
        height: 100px;
        width: 100px;
        margin-top: -80px;
    }

    .slider__item-01{
        background-image: url("../assets/all-image/slider/finalTemplateMobile.jpeg");
        background-repeat: no-repeat;
        background-position: center, center;
        width: 100%;
        height: 100%;
        background-size:cover;
        overflow-x: hidden;
    }

    .btn__mobile {
        margin-left: -90px;
    }
}