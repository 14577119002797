.localcar__form{
    width: 99%;
    height: 130%;
    background: rgba(182, 180, 180, 0.314);
    padding: 10px;
    margin: 0 50px;
    margin-top: -5px;
    border-radius: 1px;
    margin-bottom: 100px;
    border-radius: 5px;
    margin-left: 10px;
}

.localcar__form form .title{
    font-size: 39px;
    font-weight: 600;
    margin: 6px 0;
    color: #070707;
    margin-left: 100px;
}

.container__form form .feilds{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

form .feilds .input-feild {
    display: flex;
    width: calc(100% / 4 - 10px);
    flex-direction: column;
    margin: 4px 0;
    margin-left: 100px;
}

.input-feild input{
    outline: none;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 400;
    color: #333;
    border: 1px solid #aaa;
    height: 42px;
    margin: 8px 0;
    font-size: 18px;
}

.input-feild input:is(:focus, :valid){
    box-shadow: 0 3px 6px rgb(0, 0, 0, 0.1);
}

.input-feild input[type="date"] {
    color: #707070;
    font-family: bold;
    font-size: 18px;
    color: #090909;
}

.input-feild input[type="date"]:valid{
    color: #333;
}

.input-feild .cars{
    outline: none;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 400;
    color: #333;
    border: 1px solid #aaa;
    height: 42px;
    margin: 8px 0;
    font-family: bold;
    font-size: 18px;
}

.input-feild .btn{
    background-color: #101010;
    color: #fff;
    border-radius: 1px;
}
.input-feild .btn:hover {
    background-color: #9c0a0e;
    color: #fff;
}

::placeholder{
    color: black;
    font-family: bold;
    font-size: 18px;
}

.localcar__form .image__slider img{
    width: 650px;
    height: 550px;
    margin-left: 700px;
    margin-top: -500px;
    border-radius: 5px;
}

.all__value-data span{
    color: green;
}

.localcar__form .warning__message span{
    color: rgb(31, 30, 30);
}

.localcar__form .warning__message p{
    color: red;
    margin-right: 50%;
    font-family: Georgia, 'Times New Roman', Times, serif;
    animation: marquee 10s linear infinite;
}



/*-------------------------------------------------------------{mobile Code}----------------------------------------------*/
@media only screen and (max-width: 767px) {
    .localcar__form{
        margin-top: 10px;
        margin-left: 8px;
    }  
    
    .image__slider{
        display: none;
    }

    .localcar__form form .title{
        font-size: 20px;
        text-align: center;
        justify-content: center;
        margin-left: -20px;
    }

    .input-feild input {
        width: 250px;
        margin-left: -80px;
    }

    .input-feild .btn{
        width: 280px;
        margin-left: -95px;

        /* margin-left: 10px; */
    }

    .localcar__form{
        width: 95%;
    }

    .localcar__form .warning__message span{
        color: rgb(31, 30, 30);
    }
    
    .localcar__form .warning__message p{
        color: red;
        margin-left: -10px;
        font-family: Georgia, 'Times New Roman', Times, serif;
        font-size: 10px;
        inline-size: 310px;
    }
}

@media only screen and (min-width:668px) and (max-width: 1023px) {
    .localcar__form{
        margin-top: -10px;

    }  
    
    .localcar__form .image__slider img{
        display: none;
    }

    .localcar__form form .title{
        font-size: 30px;
        text-align: center;
        justify-content: center;
        margin-left: 20px;
    }

    .input-feild input {
        width: 500px;
        margin-left: 30px;
    }

    .input-feild .btn{
        width: 500px;
        margin-left: 30px;
    }

    .localcar__form{
        width: 95%;
    }

    .localcar__form .warning__message span{
        color: rgb(31, 30, 30);
    }
    
    .localcar__form .warning__message p{
        color: red;
        font-family: Georgia, 'Times New Roman', Times, serif;
        font-size: 15px;
        inline-size: 730px;
    }

}