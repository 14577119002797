.section__title-service{
    justify-content: center;
    text-align: center;
    margin-left: -20px;
    font-size: 30px;
}

.section__title-car{
    color: rgb(133, 13, 13);
    font-family: sans-serif;
    font-weight: 900;
    margin-left: -20px;
    font-size: 30px;   
}

.section__title-setpSection{
    color: rgb(133, 13, 13);
    font-family: sans-serif;
    font-weight: 900;
    margin-left: -20px;
    margin-top: -80px;
}

.work__step{
    text-align: center;
    justify-content: center;
    margin-left: 30px;
}

.section__subtitle-blog{
    color: rgb(133, 13, 13);
    font-family: sans-serif;
    font-weight: 900;
    margin-left: -20px;
    margin-bottom: 20px;   
}

.section__subtitle-Client{
    color: rgb(133, 13, 13);
    font-family: sans-serif;
    font-weight: 900; 
    margin-left: -20px;
}


section .text-center-service{
    margin-top: -20px;
    text-align: center;
    justify-content: center;
}

section .section__title-service {
    margin-top: -50px;
    color: rgb(133, 13, 13);
    font-family: sans-serif;
    font-weight: 900; 
}

@media only screen and (min-width: 300px) and (max-width: 767px)  {
    .section__title-setpSection{
        margin-top: -40px;
    }

    .section__title-service{
        margin-left: 10px;
    }

    .car__price{
        margin-left: 30px;
        margin-top: 10px;
        inline-size: 270px;
        font-size: 12px;
    }

}


@media only screen and (min-width:668px) and (max-width: 1023px) {
    .section__subtitle-blog{
        margin-top: 50px;
    }
}
