 .social__link-icon {
    text-decoration: none;
    color: #f10707;
    font-weight: 500;
    font-size: 1.2rem;
}

.contact__detail-form {
    background: #e6e2e2;
    margin-top: 15px;
    width: 70%;
}

.contact__form {
    padding: 12px;
    border-radius: 5px;
}

.boxes__input{
    border-style: none;
    padding: 7px 35px;
    text-align: left;
}


.heading__title h6 {
    font-size: 2rem;
    margin-bottom: 5px;
    text-align: center;
    color: #901111;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.contact__btn {
    padding: 7px 55px;
    background: #2d2d2d;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 5px;
    text-align: center;
    margin-left: 30%;
    border-radius: 2px;
}

.contact__btn:hover{
    background-color: #901111;
}

.contact__info {
    margin-top: 20px;
    background-color: rgb(153, 149, 149);
    border-radius: 1px;
    width: 400px;
    margin-left: 100px;
}

.title__heading h2 {
    margin-bottom: 30px;
    color: #fff;
    text-align: center;
}

.address__title {
    margin-bottom: 40px;
}

.address__title p {
    color: #fff;
    margin-left: 70px;
    font-size: 20px;
    margin-top: 20px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}

.phone__logo {
    color: #fff;
    margin-top: 20px;
    margin-left: 60px;
}

.phone__logo i {
    font-size: 18px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.gmail__logo {
    margin-top: 30px;
    margin-left: 90px;
}

.gmail__logo i {
    font-size: 35px;
    margin-top: 30px;
    color: #fff;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}

.gmail__logo span {
    color: #fff;
    font-size: 30px;
    margin-left: 30px;
}

.social__link {
    margin-bottom: 60px;
}

.social__link h3 {
    font-size: 40px;
    margin-top: 20px;
    color: rgb(157, 13, 13);
    margin-left: 90px;
    font-family: bold;
}

.social__link a {
    color: #fff;
    margin-left: 80px;
    font-size: 30px;
}

.social__link a:hover {
    color: #f10707;
}

iframe {
    margin-top: 5px;
    margin-left: 0px;
    width: 1515px;
    height: 350px;
    border-radius: 1px;
} 

.contact__form .textarea__input{
    border-style: none;
    padding: 30px 120px;
    text-align: right;
    margin-left: 45px;
}

::placeholder{
    padding: auto;
}

/* {------------------------------------------------ Mobile Screen Code ----------------------------------------------} */

 @media only screen and (max-width: 768px) {
    iframe{
        margin-top: 10px;
        width: 380px;
        height: 250px;
        margin-left: 0px;
    }

    .contact__detail-form{
        background: #e6e2e2;
        margin-top: 15px;
        width:320px;
        margin-left: -10px;
    }

    .contact__detail-form input{
        width: 250px;
        margin-left: 25px;
    }

    .contact__info {
        margin-top: 60px;
        background-color: rgb(153, 149, 149);
        border-radius: 1px;
        width: 340px;
        margin-left: -15px;
    }

    .contact__btn{
        margin-left: 60px;
        margin-bottom: 1rem;
    }

    .address__title p{
        color: #fff;
        margin-left: 40px;
        font-size: 15px;
        margin-top: 20px;
    }

    .phone__logo{
        font-size: 10px;
        margin-left: 30px;
    }

    .gmail__logo i{
        font-size: 20px;
        margin-left: 30px;
    }

    .gmail__logo a{
        font-size: 10px;
        margin-left: -30px;
    }

    .social__link h3{
        margin-left: 60px;
    }

    .social__link a{
        margin-left: 60px;
    }

    input ::placeholder{
        font-size: 10px;
    }

} 

@media only screen and (min-width:668px) and (max-width: 1023px) {
    .contact__form {
        padding: 8px;
        border-radius: 5px;
    }
    
    .boxes__input{
        border-style: none;
        padding: 7px 25px;
        margin-left: -40px;
        width: 50px;
    }

    .contact__detail-form{
        background: #e6e2e2;
        margin-top: 15px;
        width:380px;
        margin-left: -40px;
    }

    .contact__info{
        margin-left: -50px;
    }
}