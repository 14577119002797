.services-submenu {
    width: 15rem;
    position: absolute;
    top: 55px;
    list-style: none;
    text-align: start;
    z-index: 10;

}

.services-submenu li {
    background: #fff;
    cursor: pointer;
}

.services-submenu .clicked {
    display: none;
}

.submenu-item {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #fff;
    padding: 5px;
}

.submenu-item:hover {
    background: #d0cdcd;
}

/*------------------------------[mobile submenucode]---------------------*/

@media only screen and (min-width: 300px) and (max-width: 767px)  {
    .services-submenu {
        width: 10rem;
        position: absolute;
        top: 55px;
        list-style: none;
        text-align: start;
        z-index: 10;
    }

    .services-submenu li {
        background: #fff;
        cursor: pointer;
    }

    .services-submenu .clicked {
        display: none;
    }
    
    .submenu-item {
        display: block;
        width: 100%;
        height: 100%;
        text-decoration: none;
        color: #fff;
        padding: 5px;
    }
    
    .submenu-item:hover {
        background: #d0cdcd;
    }
    
}


@media only screen and (min-width:668px) and (max-width: 1023px) {
    .services-submenu {
        width: 15rem;
        position: absolute;
        top: 55px;
        list-style: none;
        text-align: start;
        z-index: 10;
    
    }
    
    .services-submenu li {
        background: #fff;
        cursor: pointer;
    }
    
    .services-submenu .clicked {
        display: none;
    }
    
    .submenu-item {
        display: block;
        width: 100%;
        height: 100%;
        text-decoration: none;
        color: #fff;
        padding: 5px;
    }
    
    .submenu-item:hover {
        background: #d0cdcd;
    }
}