.footer__class{
    border-right: 3px solid #7c8a972f;
    height: 300px;
}

.footer {
    background: #0c0c0c;
    padding-top: 30px;
    padding-bottom: 5px;
}

.footer__logo i {
    color: #fff;
    font-weight: 200;
}

.footer__logo span {
    color: #fff;
    font-weight: 500;
}

.footer__logo-content {
    color: rgba(255, 255, 255, 0.715);
    margin-top: 15px;
    line-height: 30px;
}

.header__bottom__help {
    display: flex;
    align-items: center;
    column-gap: 0.4rem;
}

.header__bottom__help i {
    background: #0f0e0e;
    color: #fff;
    padding: 2px 4px;
    border-radius: 50px;
    font-size: 1rem;
}

.office__details {
    margin-left: 70px;
    color: #b1a8a8;
    gap: 0.5rem;
    font-family: serif;
    font-size: -5px;
}

.office__details h5 {
    color: #fff;
    font-size: 1.5rem;
    margin-bottom: 20px;
    margin-left: -70px;
}

.office__details p {
    margin-left: -50px;
    column-gap: .9rem;
    font-size: 15px;
    margin-left: -150px;
}

.office__details span {
    margin-bottom: 100px;
    font-size: 20px;
}

h3 {
    color: #fff;
    margin-right: -40px;
}

.follow__us ul{
    display: flex;
    top: 50%;
    margin: 10px;
    margin-top: 20px;
    margin-bottom: 30px;
}

.follow__us ul li{
    list-style: none;
    margin: 0 15px;
    text-align: center;

}

.follow__us ul li i{
    font-size: 30px;
    line-height: 60px;
    transition: .6s;
    color: #000;
}

.follow__us ul li i:hover{
    color: #fff;
}

.follow__us ul li a{
    position: relative;
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #fff;
    text-align: center;
    transition: .6s;
    box-shadow: 0 5px 4px rbga(0, 0, 0, .5);
}

.follow__us ul li a:hover{
    transform: translate(0, -10px);
}

.follow__us ul li:nth-child(1) a:hover{
    background-color: #3b5999;
}

.follow__us ul li:nth-child(2) a:hover{
    background-color: #e4405f;
}

.follow__us ul li:nth-child(3) a:hover{
    background-color: red;
}

.phone__links{
    margin-bottom: 50px;
}
.phone__links a{
    color: #fff;
    font-size: 13px;
}

.phone__links a:hover{
    color: #fff;
}

.quick__link {
    border: none;
    background: transparent !important;
    margin-left: -20px;
}

.quick__link a {
    color: rgba(255, 255, 255, 0.715);
    text-decoration: none;
    font-weight: 200;
    transition: 0.3s;
}

.footer__link-title {
    color: #fff;
}

.quick__link a:hover {
    color: #e31515;
}

.newsletter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 9px;
    border: 1px solid #7c8a972f;
    border-radius: 30px;
    background: #fff;
  }
  
  .newsletter input {
    background: transparent;
    color: #0a0a0a;
    border: none;
    outline: none;
  }
  
  .newsletter span i {
    color: #0a0a0a;
    cursor: pointer;
  }

  .footer__bottom {
    border-top: 1px solid #7c8a972f;
}

.footer__bottom i{
    text-align: center;
    line-height: 3em;
}

.footer__bottom .trademarks{
    margin-bottom: 10px;
    line-height: 1em;
    font-size: 10px;
}
  

/* {------------------------------------------------ Mobile Screen Code ----------------------------------------------} */
@media only screen and (min-width: 300px) and (max-width: 767px)  {
    .footer{
        margin-bottom: -10px;
    }
    .footer__logo-content {
      font-size: 0.8rem;
    }

    .quick__link,
    .office__info {
      font-size: 0.8rem;
      line-height: 1rem;
      font-family: bold;
    }
  
    .footer__link-title {
      font-size: 1rem;
      margin-top: 15px;
    }
  
    .footer__bottom p {
      font-size: 0.7rem;
    }

    .footer__class{
        margin-bottom: 20px;
    }

    .link__title-Follow{
        margin-top: -120px;
    }

    .link__title i{
        margin-left: 50px;
    }

    .phone__links{
        font-size: 0.9rem;
        margin-left: 40px;
    }

    .footer__class{
        border-right: 3px solid #0505052f;
        height: 300px;
    }

    .quick__link{
        margin-left: 5px;
        
    }

    .footer__bottom .trademarks{
        margin-left: 100px;
        inline-size: 310px;
        text-align: justify;
      }

    .office__details h5{
        margin-top: 20px;
        margin-left: -20px;
    }
    .office__details p {
        margin-left: -50px;
        column-gap: .9rem;
        font-size: 15px;
        margin-left: -10px;
    }

  }

@media only screen and (min-width:668px) and (max-width: 1023px) {
    .footer{
        margin-bottom: 550px;
    }

    .footer__logo-content {
        font-size: 0.8rem;
      }
  
      .quick__link,
      .office__info {
        font-size: 0.8rem;
       line-height: 1.5rem;
       font-family: bold;
      }

     .footer__link-title {
        font-size: 1rem;
        margin-top: -15px;
        margin-left: 20px;
        row-gap: 30px;
      }
    
      .footer__bottom p {
        font-size: 0.7rem;
      }
  
      .footer__class{
          margin-bottom: 20px;
      }
  
      .link__title-Follow{
          margin-top: 70px;
      }
  
      .link__title i{
          margin-left: 50px;
      }
  
      .phone__links{
          font-size: 0.9rem;
      }
  
      .footer__class{
          border-right: 3px solid #0505052f;
          height: 300px;
      }
  
      .quick__link{
        margin-left: 5px;
        margin-top: -15px;   
      }

      /* .office__details h5{
        font-size: 17px;
        margin-top: -15px;
      }

      .office__details p {
        inline-size: 150px;
      } */

      .link__title {
        display: flex;
        margin-bottom: 200px;
        text-align: center;
      }

      .follow__us {
        display: flex;
        margin-left: 200px;
        margin-bottom: 20px;
      }

      .phone__links i{
        margin-bottom: -20px;
        margin-left: 200px;
      }

      .footer__bottom .trademarks{
        margin-left: 50px;
        inline-size: 550px;
        text-align: justify;
      }

      .office__details h5 {
        color: #fff;
        font-size: 1.5rem;
        margin-bottom: 10px;
        margin-left: -70px;
        margin-top: -20px;
    }
    
    .office__details p {
        margin-left: -50px;
        column-gap: .9rem;
        font-size: 15px;
        margin-left: -50px;
    }

}





