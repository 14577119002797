.section__title1{
    margin-top: -70px;
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 30px;
    color: #9c0a0e;
}

.step-wizard {
    height: 10vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 70px;
    margin-bottom: 70px;
}

.step-wizard-list {
    background: #fff;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);
    color: #333;
    list-style-type: none;
    border-radius: 10px;
    display: flex;
    padding: 20px 10px;
    position: relative;
    z-index: 10;
}

.step-wizard-item {
    padding: 0 20px;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    min-width: 200px;
    position: relative;
}

.step-wizard-item+.step-wizard-item:after {
    content: "";
    position: absolute;
    left: 0;
    top: 19px;
    background: #1b1c1c;
    width: 100%;
    height: 2px;
    transform: translateX(-50%);
    z-index: -10;
}

.progress-count {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 600;
    margin: 0 auto;
    position: relative;
    z-index: 10;
    color: transparent;
}

.progress-count:after {
    content: "";
    height: 40px;
    width: 40px;
    background: #151515;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    z-index: -10;
}

.progress-count:before {
    content: "";
    height: 10px;
    width: 20px;
    border-left: 3px solid #fff;
    border-bottom: 3px solid #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -60%) rotate(-45deg);
    transform-origin: center center;
}

.progress-label {
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
    color: #080808;
}

.progress-label3{
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
    color: #080808;
}

.progress-label4{
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
    color: #080808;
}

.icons_list img{
    height: 150px;
    width: 150px;
    border-radius: 300px;
    border: 2px solid #930303;
    clip-path: circle();
    transition: 1s;
}

.icons_list img:hover{
    transform: scale(0.5);
}

.icons_list1 img{
    height: 150px;
    width: 150px;
    border-radius: 300px;
    border: 2px solid #930303;
    clip-path: circle();
    transition: 1s;
}

.icons_list1 img:hover{
    transform: scale(0.5);
}

.icons_list2 img{
    height: 150px;
    width: 150px;
    border-radius: 300px;
    border: 2px solid #930303;
    clip-path: circle();
    transition: 1s;
}

.icons_list2 img:hover{
    transform: scale(0.5);
}

.icons_lits3 img{
    height: 150px;
    width: 150px;
    border-radius: 300px;
    border: 2px solid #930303;
    clip-path: circle();
    transition: 1s;
}

.icons_list3 img:hover{
    transform: scale(0.5);
}

.icons_lits3 p{
    text-align: center;
    margin-left: 25px;
}

.current-item .progress-count:before,
.current-item~.step-wizard-item .progress-count:before {
    display: none;
}

.current-item~.step-wizard-item .progress-label {
    opacity: 0.5;
}

.current-item .progress-count {
    color: #fff;
}

.current-item1 .progress-count {
    color: #fff;
}

.current-item2 .progress-count {
    color: #fff;
}

.icons_list1 p{
    margin-left: 30px;
}

/* {------------------------------------------------ Mobile Screen Code ----------------------------------------------} */
@media only screen and (max-width: 768px) {
    .step-wizard-item{
        flex-direction: column;
        background-color: #fff;
    }

    .step-wizard {
        flex-direction: column;
        margin-top: 580px;
        background-color: #fff;
        margin-bottom: 600px;
    }

    .step-wizard-list{
        flex-direction: column;
        background-color: #fff;
    }

    .step-wizard-item{
        flex-direction: column;
        background-color: #fff;
    }

    .progress-count{
        flex-direction: column;
        background-color: #fff;
    } 
    
    .step-wizard-item+.step-wizard-item:after {
        content: "";
        position: absolute;
        left: 0;
        top: 19px;
        background: #1b1c1c;
        width: 100%;
        height: 2px;
        transform: translateX(-50%);
        z-index: -10;
        display: none;
    }
    
}

@media only screen and (min-width:668px) and (max-width: 1023px) {
    /* .step-wizard{
        margin-top: 30px;
    } */
}
