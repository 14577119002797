@import url('https://fonts.googleapis.com/css?family=Allura|Josefin+Sans');

.wrapper {
    margin-top: 10%;
    padding-top: 40px;
    text-align: center;
    display: none;
}

.wrapper h1 {
    font-size: 50px;
    font-weight: 600;
    color: #9c0a0e;
    display: none;
}
.about__png-img {
    margin-bottom: 300px;
    margin-right: 50px;
    margin-top: 100px;
    border: 1px solid hsl(0, 0%, 87%);
    border-radius: 4px;
    padding: 5px;
    width: 600px;
    height: 300px;
}

.mobile__photo-describe{
    display: none;
}

.award__section {
    display: flex;
    gap: 40px;
    margin-bottom: 50px;
    background-color: #fff;
    display: none;
}

p{
    text-align: center;
    margin-right: 50px;
}

.about__page-content h1{
    color: #070707;
    width: -200px;
}

.section__description-title {
    font-size: 20px;
    font-family: "Garamond", sans-serif;
    flex-direction: column;
    display:flex;
    color: #070707;
    font-size: 19px;
    column-gap: normal;
    inline-size: 700px;
    /* text-indent: 50px; */
     text-align: justify;
    letter-spacing: 1px;
}

.about__page-content h1{
    font-size: 36px;
    color: black;
    font-family: "Franklin Gothic Medium";
    justify-content: center;
}

.section__title-about{
    font-size: 36px;
    color:#9c0a0e;
    font-family: "Franklin Gothic Medium";
    margin-left: -5px;
}

.about__page-content .about__accolades {
    font-size: 20px;
    font-family: "Garamond", sans-serif;
    flex-direction: column;
    display:flex;
    color: #070707;
    font-size: 19px;
    column-gap: normal;
    inline-size: 700px;
    /* text-indent: 50px; */
     text-align: justify;
    letter-spacing: 1px;
}

.about__roll h1{
    font-size: 36px;
    color: black;
    font-family: "Franklin Gothic Medium";
    justify-content: center;
    margin-top: -150px;
}

.about__roll p{
    font-size: 20px;
    font-family: "Garamond", sans-serif;
    flex-direction: column;
    display:flex;
    color: #070707;
    font-size: 19px;
    column-gap: normal;
    /* inline-size: 700px;
    text-indent: 50px; */
     text-align: justify;
    letter-spacing: 1px;
}

.our__award-section {
    margin-top: 60px;
}

.our__award-section p {
    margin-top: -20px;
    margin-left: -45px;
}

.our__award-section {
    margin-top: 40px;
}

.our__award-section p {
    margin-top: -130px;
    margin-left: 50px;
}

.our__award-section span {
    margin-left: 30px;
}

.award_section {
    text-align: center;
    padding-top: 40px;
}

.about__page-content{
    text-align: justify;
}

.about__page-content .about__page-paragraph{
    inline-size: 590px;
    text-align: justify;
}

.about__page-content .trust{
    margin-top: 50px;
    margin-left: -600px;
}

.about__page-content .about__page-secondpara{
    inline-size: 1300px;
    text-align: justify;
    margin-left: -650px;
}
 
/* {------------------------------------------------ Mobile Screen Code ----------------------------------------------} */

@media only screen and (min-width: 300px) and (max-width: 667px){ 
    .about__png-img {
        width: 300px;
        height: 100px;
        margin-top: 40px;
        display: none;
    }

    .about__page-content h2{
        margin-top: 10px;
        text-align: left;
    }

    .about__page-content{
        text-align: justify;
    }

    .section__description-title{
        text-align: justify;
        letter-spacing: 1px;    
        font-size: 18px;
        inline-size: 290px;
        margin-top: 10px;
    }

    .about__page-content h1{
        font-size: 20px;
        font-style: bold;
        font-family: "Franklin Gothic Medium";
    }

    .about__page-content .about__accolades {
        text-indent: 10px;
        text-align: justify;
        letter-spacing: 1px;    
        font-size: 18px;
        inline-size: 350px;   
    }

    .about__page-section .about__roll h1{
        margin-top: 10px;
        font-size: 25px;
    }

    .about__page-section .about__roll p{
        inline-size: 350px;   
    }

    .award__section{
        display: none;
        justify-content: center;
    }

    .about__page-content .about__page-paragraph{
        inline-size: 290px;
        text-align: justify;
    }

    .about__page-content h1{
        margin-top: 20px;
    }

    .about__page-content .about__page-secondpara{
        inline-size: 290px;
        text-align: justify;
        margin-inline-start: auto;
    }
  }

  @media only screen and (min-width:668px) and (max-width: 1023px) {
    .about__png-img {
        margin-bottom: 100px;
        margin-left: -60px;
        margin-top: 100px;
        border: 1px solid hsl(0, 0%, 87%);
        border-radius: 4px;
        padding: 5px;
        width: 400px;
        height: 100px;
    }

    .section__description-title{
        font-size: 20px;
        font-family: "Garamond", sans-serif;
        flex-direction: column;
        display:flex;
        color: #070707; 
        column-gap: normal;
        inline-size: 380px;
        text-align: justify;
        letter-spacing: 1px;
        margin-bottom: 50px;
    }

    h1{
        text-align: center;
        justify-content: center;
        margin-left: -450px;
    }

    .about__accolades{
        margin-bottom: 200px;
        margin-left: -400px;
    }

    .about__roll h1{
        margin-left: -60px;
    }

    .about__page-content{
        text-align: justify;
    }

    .about__page-content{
        margin-bottom: 50px;
    }

    .about__page-content .about__page-paragraph{
        margin-left: -400px;
        text-align: justify;
        inline-size: 750px;
    }

    .about__page-content .about__page-secondpara{
        margin-left: -400px;
        text-align: justify;
        inline-size: 750px;
    }
    
  }

