.vip__operation-page {
    background-image: url("../assets/all-image/carsimage/vipService2.jpg");
    height: 500px;
    background-repeat: no-repeat;
    margin-bottom: 20px;
    background-size: 1530px 550px;
    justify-content: center;
}

.vip__title {
   text-align: center;
   font-size: 50px;
   margin-bottom: 80px;
   font-family: "Franklin Gothic Medium";
}
 .vip__title p{
   font-size: 25px;
   font-family: "Garamond", sans-serif;
   color: #f00e0e;
   margin-left: 50px;
   font-style: italic;
   font-family: bold;
 }

 .vip__slide-container{
   margin-top: -70px;
   border-left: 1px solid black;
   height: 1850px;
   margin-left: 200px;
   border-right: 1px solid black;
   margin-right: 200px;
   margin-bottom: 450px;
   border-top: 1px solid black;
   border-bottom: 1px solid black;
   box-shadow: 0 0 5px;
 }

.vip__slide-container p{
   font-family: "Garamond", sans-serif;
   font-size: 22px;
   text-align: justify;
   margin-left: 43px;
}

.vip__slide-container span{
   color: #f00e0e;
   font-family: "Garamond", sans-serif;
   font-family: bold;
}

.vip__services h1{
   font-size: 26px;
   color: black;
   font-family: "Franklin Gothic Medium";
   justify-content: center;
}

.vip__rnk h1{
   font-size: 50px;
   color: #f00e0e;

}

.vip__rnk p{
   text-align: center;
}

.vip__chauffer h1{
   font-size: 26px;
   color: black;
   font-family: "Franklin Gothic Medium";
   justify-content: center;
}

.vip__chauffer p{
   text-align: center;
   margin-left: 2.2rem;  
}

.vip__gps{
   margin-left: -2.6rem;
}

.vip__security p{
   margin-left: 20px;
}
.vip__comfort p{
   margin-right: 39px;
}

.vip__extensive p{
   margin-right: 125px;
}

/*------------------------------------------------------*/
 .vip__operation-container .accordion{
    margin-left: 50px;
    width: 1200px;
    margin-bottom: 100px;
    margin-top: -400px;
    margin-left: 135px;
 }

 .item {
    background: #f0ebe1;
    margin-bottom: 10px;
    padding: 10px 20px;
 }

 .content{
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
 }

 .content .show{
    height: auto;
    max-height: 9999px;
    transition: all 0.5s cubic-bezier(1,0,1,0);
 }

 .accordion{
    max-width: 1400px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    overflow: hidden;
    font-family: 'Quicksand', sans-serif;
 }

 .accordion__title {
    text-align: center;
    font-size: 2rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    background-color: black;
    color: #f0ebe1;
 }
 
 .accordion__label,
    .accordion__content{
        padding: 14px 20px;
 }

 .accordion__label{
    display: block;
    color: black;
    font-weight: 600;
    cursor: pointer;
    position: relative;
    transition: background 0.1s;
 }

 .accordion__label:hover{
    background: rgba(0, 0, 0, 0.1);
 }

 .accordion__label::after{
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    width: 12px;
    height: 6px;
    background-image: url('data:image/svg+xml;utf8,<svg width="100" height="50" xmlns="http://www.w3.org/2000/svg"><polygon points="0,0 100,0 50,50" style="fill:%black;" /></svg>');
   background-size: contain;
   transition: transform 0.4s;
 }

 .accordion__content {
    background: #ffffff;
    line-height: 1.6;
    font-size: 0.99em;
    display: none;
    margin-left: 5px;
  }
  
  .accordion__input {
    display: none;
  }
  
  .accordion__input:checked ~ .accordion__content {
    display: block;
  }
  
  .accordion__input:checked ~ .accordion__label::after {
    transform: translateY(-50%) rotate(0.5turn);
  }


  /*--------------------------------[mobile screen]-------------------*/
@media only screen and (min-width: 300px) and (max-width: 767px)  {
   .vip__operation-page {
      background-image: url("../assets/all-image/carsimage/vipService2.jpg");
      height: 200px;
      background-repeat: no-repeat;
      margin-bottom: 20px;
      background-size: cover;
      justify-content: center;
   }

   .vip__operation-container .vip__title {
      margin-top: -20px;
   }

   .vip__title p{
      font-size: 12px;
      font-family: "Garamond", sans-serif;
      color: #f00e0e;
      font-style: italic;
      font-family: bold;
      text-align: center;
      justify-content: center;
      margin-left: 50px;
    }

    .vip__operation-container .vip__slide-container{
      margin-top: -70px;
      border-left: 1px solid black;
      height: 4100px;
      margin-left: 10px;
      border-right: 1px solid black;
      margin-right: 30px;
      margin-bottom: 700px;
      border-top: 1px solid black;
      border-bottom: 1px solid black;
      box-shadow: 0 0 5px;
      inline-size: 340px;
      text-align: justify;
    }

    .vip__operation-container .vip__slide-container p{
      inline-size: 310px;
      text-align: justify;
      margin-left: 10px;
    }
    .vip__extensive p{
      justify-content: center;
      margin-right: 2px;
    }

    .vip__operation-container .vip__gps h1{
      margin-left: 30px;
    }

    .vip__operation-container .vip__gps p{
      margin-left: 50px;
    }

    .vip__operation-container .accordion{
      max-width: 340px;
      box-shadow: 0 0 10px rgba(57, 10, 10, 0.2);
      border-radius: 5px;
      overflow: hidden;
      font-family: 'Quicksand', sans-serif;
      margin-top: -600px;
      margin-left: 10px;
      margin-bottom: 100px;
      margin-right: -10px;
      }
   
      .vip__operation-containe .accordion__content p {
         font-family: "Trebuchet MS", Verdana, sans-serif;
         margin-right: -10px;
         inline-size: 100px;
         text-align: justify;
      }  
}
  
@media only screen and (min-width:668px) and (max-width: 1023px) {
   .vip__operation-page {
      background-image: url("../assets/all-image/carsimage/vipService2.jpg");
      height: 500px;
      background-repeat: no-repeat;
      margin-bottom: 20px;
      background-size: cover;
      justify-content: center;
   }

   .vip__title p{
      font-size: 18px;
      font-family: "Garamond", sans-serif;
      color: #f00e0e;
      margin-left: 50px;
      font-style: italic;
      font-family: bold;
    }

    .vip__slide-container{
      margin-top: -70px;
      border-left: 1px solid black;
      height: 5000px;
      margin-left: 7px;
      border-right: 1px solid black;
      margin-right: 10px;
      margin-bottom: 450px;
      border-top: 1px solid black;
      border-bottom: 1px solid black;
      box-shadow: 0 0 5px;
    }

    .vip__extensive p{
      justify-content: center;
      margin-right: 2px;
    }

    .vip__gps p{
      margin-right: 10px;
    }

    .vip__operation-container .accordion{
      max-width: 800px;
      box-shadow: 0 0 10px rgba(57, 10, 10, 0.2);
      border-radius: 5px;
      overflow: hidden;
      font-family: 'Quicksand', sans-serif;
      margin-top: -400px;
      margin-left: 10px;
      margin-bottom: 100px;
      margin-right: 10px;
      }
   
      .accordion__content p {
         font-family: "Trebuchet MS", Verdana, sans-serif;
         margin-left: 20px;
         inline-size: 300px;
         text-align: justify;
      } 
      
      .vip__services h1{
         margin-left: 50px;
      }
}