.terms-container{
    margin-bottom: 340%;
    height: 200px;
}

.terms-title h2{
    color: #0b0b0b;
    text-align: center;
    text-decoration: none;
    padding: 30px;
    font-weight: 600;
    font-size: 30px;
    font-family: bold;
}

.terms-para1 h2 {
    font-weight: 600;
    margin-left: 50px;
    color: black;
    font-size: 12px;
}

.terms-para1 h5{
    font-weight: 600;
    margin-left: 50px;
    margin-top: 30px;
    color: black;
    font-size: 11px;
}

.terms-para1 p{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 70px;
    margin-right: 50px;
}

.booking-process h5{
    font-weight: 600;
    margin-left: 50px;
    margin-top: 30px;
    color: black;
    font-size: 11px;
}

.terms-container .booking-process p{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 70px;
    margin-right: 50px;
}

.terms-container .customer-response h5{
    font-weight: 600;
    margin-left: 50px;
    margin-top: 30px;
    color: black;
    font-size: 11px;
}

.terms-container .customer-response p{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 70px;
    margin-right: 50px;
}

.replacement-vehicle h5{
    font-weight: 600;
    margin-left: 50px;
    margin-top: 30px;
    color: black;
    font-size: 11px;
}

.replacement-vehicle p{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 70px;
    margin-right: 50px;
}

.idemnity h5{
    font-weight: 600;
    margin-left: 50px;
    margin-top: 30px;
    color: black;
    font-size: 11px;
}

.idemnity p{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 70px;
    margin-right: 50px;
}

.indemnity-span{
    color: black;
    margin-left: 7px;
}

.desclaimer h5{
    font-weight: 600;
    margin-left: 50px;
    margin-top: 30px;
    color: black;
    font-size: 11px;
}

.desclaimer p{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 70px;
    margin-right: 50px;
}


.self-drive .self-data h2 {
    font-weight: 600;
    margin-left: 50px;
    color: black;
    font-size: 12px;
}

.self-drive .self-data h5{
    font-weight: 600;
    margin-left: 50px;
    margin-top: 30px;
    color: black;
    font-size: 11px;
}

.self-drive .self-data p{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 70px;
    margin-right: 50px;
}

.self-drive .parking-process h5{
    font-weight: 600;
    margin-left: 50px;
    margin-top: 30px;
    color: black;
    font-size: 11px;
}

.self-drive .parking-process p{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 70px;
    margin-right: 50px;
}

.self-drive .customer-responsibilities h5{
    font-weight: 600;
    margin-left: 50px;
    margin-top: 30px;
    color: black;
    font-size: 11px;
}

.self-drive .customer-responsibilities p{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 70px;
    margin-right: 50px;
}


.self-drive .replacement h5{
    font-weight: 600;
    margin-left: 50px;
    margin-top: 30px;
    color: black;
    font-size: 11px;
}

.self-drive .replacement p{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 70px;
    margin-right: 50px;
}

.self-drive .indemnity h5{
    font-weight: 600;
    margin-left: 50px;
    margin-top: 30px;
    color: black;
    font-size: 11px;
}

.self-drive .indemnity p{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 70px;
    margin-right: 50px;
}

.self-drive .other-condition h5{
    font-weight: 600;
    margin-left: 50px;
    margin-top: 30px;
    color: black;
    font-size: 11px;
}

.self-drive .other-condition p{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 70px;
    margin-right: 50px;
}

.self-drive .disclaimer h5{
    font-weight: 600;
    margin-left: 50px;
    margin-top: 30px;
    color: black;
}

.self-drive .disclaimer p{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 50px;
    margin-right: 50px;
}

.genral-terms h2{
    font-weight: 600;
    margin-left: 50px;
    color: black;
    margin-top: 50px;
    font-size: 12px;
}

.genral-terms h5{
    font-weight: 600;
    margin-left: 50px;
    margin-top: 30px;
    color: black;
}

.genral-terms  p{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 50px;
    margin-right: 50px;
}

.genral-terms h5{
    font-weight: 600;
    margin-left: 50px;
    margin-top: 30px;
    color: black;
    font-size: 11px;
}

.genral-terms  p{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 70px;
    margin-right: 50px;
}

.booking-process h5{
    font-weight: 600;
    margin-left: 50px;
    margin-top: 30px;
    color: black;
    font-size: 11px;
}

.booking-process  p{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 70px;
    margin-right: 50px;
}

.cancle h5{
    font-weight: 600;
    margin-left: 50px;
    margin-top: 30px;
    color: black;
    font-size: 11px;
}

.cancle  p{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 70px;
    margin-right: 50px;
}

.customer-responsible h5{
    font-weight: 600;
    margin-left: 50px;
    margin-top: 30px;
    color: black;
    font-size: 11px;
}

.customer-responsible  p{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 70px;
    margin-right: 50px;
}

.replace-vehicle h5{
    font-weight: 600;
    margin-left: 50px;
    margin-top: 30px;
    color: black;
    font-size: 11px;
}

.replace-vehicle  p{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 70px;
    margin-right: 50px;
}

.indemnity h5{
    font-weight: 600;
    margin-left: 50px;
    margin-top: 30px;
    color: black;
    font-size: 11px;
}

.indemnity  p{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 70px;
    margin-right: 50px;
}

.other-condition h5{
    font-weight: 600;
    margin-left: 50px;
    margin-top: 30px;
    color: black;
    font-size: 11px;
}

.other-condition  p{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 70px;
    margin-right: 50px;
}

.disclaimer h5{
    font-weight: 600;
    margin-left: 50px;
    margin-top: 30px;
    color: black;
    font-size: 11px;
}

.disclaimer  p{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 70px;
    margin-right: 50px;
}

.note h5{
    font-weight: 600;
    margin-left: 50px;
    margin-top: 30px;
    color: black;
    font-size: 11px;
}

.note  p{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 70px;
    margin-right: 50px;
}


@media only screen and (min-width: 300px) and (max-width: 767px)  {
    .terms-container{
        margin-bottom: 3540%;
        height: 200px;
    }

    .terms-container .terms-title h2{
        font-size: 30px;
    }

    .terms-container .terms-para1 h2{
        font-size: 20px;
    }
}

@media only screen and (min-width:668px) and (max-width: 1023px) {
}