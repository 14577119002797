.longterm__operation-page {
  background-image: url("../assets/all-image/carsimage/longTermService.jpg");
  height: 500px;
  background-repeat: no-repeat;
  margin-bottom: 20px;
  background-size: 1500px 550px;
}
.longterm__title{
  text-align: center;
    font-size: 50px;
    margin-bottom: 80px;
    font-family: "Franklin Gothic Medium";
}

.longterm__title p{
  font-size: 45px;
    font-family: "Garamond", sans-serif;
    color: #f00e0e;
    margin-left: 50px;
    font-style: italic;
    font-family: bold;
}

.longterm__container{
  margin-top: -70px;
   border-left: 1px solid black;
   height: 1700px;
   margin-left: 200px;
   border-right: 1px solid black;
   margin-right: 200px;
   margin-bottom: 450px;
   border-top: 1px solid black;
   border-bottom: 1px solid black;
   box-shadow: 0 0 5px;
}

.longterm__container p{
  font-family: "Garamond", sans-serif;
   font-size: 22px;
   text-align: justify;
   margin-left: 43px;
}

.longterm__special h1{
  font-size: 26px;
   color: black;
   font-family: "Franklin Gothic Medium";
   justify-content: center;
}

.longterm__services .book__today {
  text-align: center;
  font-size: 35px;
  font-family: "Garamond", sans-serif;
}


.long__rnk h1{
  font-size: 46px;
  color: #f00e0e;
   font-family: "Franklin Gothic Medium";
   justify-content: center;
}

.long__rnk p{
  font-family: "Garamond", sans-serif;
   text-align: center;
}

.longterm__chauffer h1{
  font-size: 26px;
   color: black;
   font-family: "Franklin Gothic Medium";
   justify-content: center;
}

.longterm__chauffer p{
  margin-left: 35px;
}

/* .longterm__gps p{
  margin-left: 5px;
} */

.book__today {
  color: red;
}


.longterm__operation-container .accordion{
    max-width: 1300px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    overflow: hidden;
    font-family: 'Quicksand', sans-serif;
    margin-top: -400px;
    margin-bottom: 100px;
    margin-left: 80px;
 }

 .accordion__title {
    text-align: center;
    font-size: 2rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    background-color: black;
    color: #f0ebe1;
 }
 
 .accordion__label,
    .accordion__content{
        padding: 14px 20px;
 }

 .accordion__label{
    display: block;
    color: black;
    font-weight: 600;
    cursor: pointer;
    position: relative;
    transition: background 0.1s;
 }

 .accordion__label:hover{
    background: rgba(0, 0, 0, 0.1);
 }

 .accordion__label::after{
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    width: 12px;
    height: 6px;
    background-image: url('data:image/svg+xml;utf8,<svg width="100" height="50" xmlns="http://www.w3.org/2000/svg"><polygon points="0,0 100,0 50,50" style="fill:%black;" /></svg>');
   background-size: contain;
   transition: transform 0.4s;
 }

 .accordion__content {
    background: #ffffff;
    line-height: 1.6;
    font-size: 0.99em;
    display: none;
    margin-left: 5px;
  }
  
  .accordion__input {
    display: none;
  }
  
  .accordion__input:checked ~ .accordion__content {
    display: block;
  }
  
  .accordion__input:checked ~ .accordion__label::after {
    transform: translateY(-50%) rotate(0.5turn);
  }

  /*---------------------------------[mobile code]---------------------*/
@media only screen and (min-width: 300px) and (max-width: 767px)  {
  .longterm__operation-page {
    background-image: url("../assets/all-image/carsimage/longTermService.jpg");
    height: 200px;
    background-repeat: no-repeat;
    margin-bottom: 20px;
    background-size: cover;
  }

  .longterm__operation-container .longterm__title{
      text-align: center;
      font-size: 30px;
      margin-bottom: 80px;
      font-family: "Franklin Gothic Medium";
  }
  
  .longterm__operation-container .longterm__title p{
    font-size: 12px;
      font-family: "Garamond", sans-serif;
      color: #f00e0e;
      margin-left: 50px;
      font-style: italic;
      font-family: bold;
  }

  .longterm__operation-container .longterm__container{
    margin-top: -70px;
     border-left: 1px solid black;
     height: 3800px;
     margin-left: 10px;
     border-right: 1px solid black;
     margin-right: 10px;
     margin-bottom: 450px;
     border-top: 1px solid black;
     border-bottom: 1px solid black;
     box-shadow: 0 0 5px;
  }

  .longterm__operation-container .longterm__container p{
    inline-size: 310px;
    text-align: justify;
    margin-left: 10px;
  }

  .longterm__services .book__today {
    text-align: center;
    font-size: 18px;
    font-family: "Garamond", sans-serif;
  }

  .longterm__operation-container .accordion{
    max-width: 1300px;
    box-shadow: 0 0 10px rgba(57, 10, 10, 0.2);
    border-radius: 5px;
    overflow: hidden;
    font-family: 'Quicksand', sans-serif;
    margin-top: -400px;
    margin-left: 10px;
    margin-bottom: 100px;
    margin-right: 10px;
 }

 .longterm__operation-container .accordion__content p{
      font-family: "Trebuchet MS", Verdana, sans-serif;
      margin-right: -10px;
      inline-size: 230px;
      text-align: justify;
 }

}

@media only screen and (min-width:668px) and (max-width: 1023px) {
  .longterm__operation-page {
    background-image: url("../assets/all-image/carsimage/longTermService.jpg");
    height: 500px;
    background-repeat: no-repeat;
    margin-bottom: 20px;
    background-size: cover;
  }

  .longterm__title{
    text-align: center;
      font-size: 30px;
      margin-bottom: 80px;
      font-family: "Franklin Gothic Medium";
  }
  
  .longterm__title p{
    font-size: 15px;
      font-family: "Garamond", sans-serif;
      color: #f00e0e;
      margin-left: 50px;
      font-style: italic;
      font-family: bold;
  }

  .longterm__container{
    margin-top: -70px;
     border-left: 1px solid black;
     height: 5000px;
     margin-left: 10px;
     border-right: 1px solid black;
     margin-right: 10px;
     margin-bottom: 450px;
     border-top: 1px solid black;
     border-bottom: 1px solid black;
     box-shadow: 0 0 5px;
  }

  .longterm__services .book__today {
    text-align: center;
    font-size: 18px;
    font-family: "Garamond", sans-serif;
  }

  .longterm__operation-container .accordion{
    max-width: 800px;
    box-shadow: 0 0 10px rgba(57, 10, 10, 0.2);
    border-radius: 5px;
    overflow: hidden;
    font-family: 'Quicksand', sans-serif;
    margin-top: -400px;
    margin-left: 10px;
    margin-bottom: 100px;
    margin-right: 10px;
 }

 .longterm__special h1{
  margin-left: 50px;
 }
}
  