/* *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
} */

.privecy-container{
    margin-bottom: 270%;
    height: 200px;
}

.privecy .privecy-title{
    color: #0b0b0b;
    text-align: center;
    text-decoration: none;
    padding: 30px;
    font-weight: 600;
    font-size: 20px;
}

.privecy-container .privcey-firstRule p{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 50px;
    margin-right: 50px;
}

.privecy-container .privcey-secondRule h4{
    font-weight: 600;
    margin-left: 50px;
    color: black;
    font-size: 12px;
}

.privecy-container .privcey-secondRule p{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 70px;
    margin-right: 50px;
}

.privecy-container .privecy-ThirdRule h4{
    font-weight: 600;
    margin-left: 50px;  
    color: black; 
    font-size: 12px;
}

.privecy-container .privecy-ThirdRule h5{
    font-weight: 600;
    margin-left: 50px; 
    color: black;
    font-size: 11px;
}

.privecy-container .privecy-ThirdRule p{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 70px;
    margin-right: 50px;
}

.privecy-container .privecy-FourthRule h4 {
    font-weight: 600;
    margin-left: 50px;
    color: black;
    font-size: 12px;

}

.privecy-container .privecy-FourthRule h6 {
    font-weight: 600;
    margin-left: 50px;
    margin-top: 20px;
    color: black;
    font-size: 11px;
}

.privecy-container .privecy-FourthRule p{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 70px;
    margin-right: 50px;
}

.privecy-container .privecy-FourthRule span {
    color: black;
    padding-left: 10px;
    font-size: 10px;
    margin-top: -30px;
}

.privecy-container .privecy-FourthRule .span-value{
    margin-top: -30px;
    margin-left: 53px;
    font-family:sans-serif;
    font-style:unset;
}

.privecy-container .privecy-FourthRule .span-value1{
    margin-top: -23px;
    margin-left: 80px;
    font-family:sans-serif;
    font-style:unset;
}

.privecy-container .privecy-fifthRule h4{
    font-weight: 600;
    margin-left: 50px;
    color: black;
    font-size: 11px;
}

.privecy-container .privecy-fifthRule span {
    color: black;
    margin-left: 45px;
    padding-right: 90px;
    font-size: 20px;
    margin-top: -200px;
}


.privecy-container .privecy-fifthRule .span-value2{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    /* margin-left: 20px;
    margin-right: 50px;  */
    margin-top: -20px;
}

.privecy-container .privecy-fifthRule .span-value3{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    /* margin-left: 20px;
    margin-right: 50px;  */
    margin-top: -20px;
}


.privecy-container .privecy-fifthRule .span-value5{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    /* margin-left: 20px;
    margin-right: 50px;  */
    margin-top: -20px;
}

.privecy-container .privecy-fifthRule .span-value1{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    /* margin-left: 20px;
    margin-right: 50px;  */
    margin-top: 5px;
}

.privecy-container .privecy-fifthRule .span-value4{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    /* margin-left: 20px;
    margin-right: 50px;  */
    margin-top: -20px;
}

.privecy-container .privecy-policy{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 50px;
    margin-right: 50px;
}

.privecy-container .privecy-fifthRule p{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 70px;
    margin-right: 50px;
}

.privecy-container .privecy-policy h4{
    color: black;
    font-size: 11px;
}

.privecy-container .privecy-policy span {
    color: black;
    margin-left: -5px;
    padding-right: 10px;
    font-size: 20px;
}

.privecy-container .privecy-policy .privecy-value1{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 20px; 
    margin-right: 10px; 
    margin-top: -1px;
}

.privecy-container .privecy-policy .privecy-value2{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 20px;
    margin-right: 50px; 
    margin-top: -20px;
}

.privecy-container .privecy-policy .privecy-value3{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 20px;
    margin-right: 50px; 
    margin-top: -20px;
}

.privecy-container .privecy-policy .privecy-value4{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 20px;
    margin-right: 50px; 
    margin-top: -20px;
}

.privecy-container .privecy-policy .privecy-value5{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 20px;
    margin-right: 50px; 
    margin-top: -20px;
}

.privecy-container .privecy-policy .privecy-value6{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 20px;
    margin-right: 50px; 
    margin-top: -20px;
}
.privecy-container .privecy-policy .privecy-value7{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 20px; 
    margin-right: 10px; 
    margin-top: -1px;
}

.privecy-container .privecy-policy .privecy-value8{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 20px; 
    margin-right: 10px; 
    margin-top: -1px;
}

.privecy-container .privecy-policy .privecy-value9{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 20px; 
    margin-right: 10px; 
    margin-top: -1px;
}

.privecy-container .privecy-policy .privecy-value10{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 20px; 
    margin-right: 10px;  
    margin-top: -1px;
}

.privecy-container .privecy-social h4{
    color: black;
    margin-left: 45px;
    font-size: 11px;
}

.privecy-container .privecy-social p{
    font-size: 10px;
    font-weight: 600;
    transition: .3s;
    text-align: justify;
    margin-left: 70px;
    margin-right: 50px;
}

@media only screen and (min-width: 300px) and (max-width: 767px)  {
    .privecy-container{
        margin-bottom: 3150%;
        height: 200px;
    }

    .privecy .privecy-title{
        color: #0b0b0b;
        text-align: center;
        text-decoration: none;
        padding: 30px;
        font-weight: 600;
        font-size: 40px;
    }

    .privecy-container .privcey-secondRule h4{
        font-weight: 600;
        margin-left: 35px;
        color: black;
    }
    

    .privecy-container .privcey-firstRule p{
        font-size: 10px;
        font-weight: 600;
        transition: .3s;
        text-align: justify;
        margin-left: 50px;
        margin-right: 50px;
    }

    .privecy-container .privecy-ThirdRule h4{
        font-weight: 600;
        margin-left: 50px;  
        color: black; 
        display: none;
    }
    
    .privecy-container .privecy-ThirdRule h5{
        font-weight: 600;
        margin-left: 35px; 
        color: black;
        font-size: 14px;
    }
    
    .privecy-container .privecy-ThirdRule p{
        font-size: 10px;
        font-weight: 600;
        transition: .3s;
        text-align: justify;
        margin-left: 50px;
        margin-right: 50px;
    }

    .privecy-container .privecy-FourthRule h4 {
        font-weight: 600;
        margin-left: 27px;
        color: red;
        font-size: 12px;
    }
    
    .privecy-container .privecy-FourthRule h6 {
        font-weight: 600;
        margin-left: 50px;
        margin-top: 20px;
        color: black;
    }
    
    .privecy-container .privecy-FourthRule p{
        font-size: 10px;
        font-weight: 600;
        transition: .3s;
        text-align: justify;
        margin-left: 50px;
        margin-right: 50px;
    }

    .privecy-container .privecy-policy h4{
        color: black;
        font-size: 20px;
        margin-left: 20px;
    }

    .privecy-container .privecy-social h4{
        font-size: 12px;
    }
    
}

@media only screen and (min-width:668px) and (max-width: 1023px) {
}