.cards{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    padding: 20px;
    grid-gap:40px;
    justify-content: space-between;
    flex-wrap: wrap;
}

.card{
    background-color: #1c1b29;
    border-radius: 15px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.18);
}
.container__cards{
    position: relative;
    clip-path: polygon(0 0,100% 0, 100% 85%, 0 100%);
}

.container__cards img{
    width: 100%;
    display: block;
    border-radius: 5px 5px 0 0;
}

.container__cards:after{
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 20px 20px 0 0;
    opacity: 0.7;
}


.card1>.container__cards:after{
    background-image: linear-gradient(135 deg,#0100ec,#fb36f4);
}

.card2>.container__cards:after{
    background-image: linear-gradient(
        135 deg,
        #0100ec,
        #fb36f4
        );
}

.details h3{
    color: black;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    margin-left: 5px;
    cursor: pointer;
}

.details h3:hover{
    color: #9c0a0e;
}

h3{
    width: max-content;
    font-size: 72px;
    position: relative;
    cursor: pointer;
    margin: 100px auto;
}

h3::after{
    content: "";
    position: absolute;
    bottom: -5px;
    height: 4px;
    width: 100%;
    left: 0;
    background-color: #fb36f4;
    transition: 0.7s;
    transition-timing-function: ease-in-out;
    transform: scaleX(0);
}

h1:hover::after{
    transform: scaleX(1);
}

.details h6{
    color: rgb(186, 26, 26);
    margin-left: 10px;
    font-weight: 600;
    font-style: bold;
}

.card .details p{
    text-align: justify;
    margin-left: 10px;
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient:unset;
}

.local__duty{
    color: #0e0d0d;
    font-weight: 600;
    text-decoration: none;
    font-size: 1rem;
    border: none;
    margin-left: 10px;
    margin-top: -30px;
    text-decoration:underline!important;
    background-color: white;
}

.local__duty:hover{
    color: #9c0a0e;
}

.hotel__operation{
    color: #0e0d0d;
    font-weight: 600;
    text-decoration: none;
    font-size: 1rem;
    border: none;
    margin-left: 10px;
    margin-bottom: -70px;
    text-decoration:underline!important;
    /* position: absolute; */
    background-color: white;
}

.hotel__operation:hover{
    color: #9c0a0e;
}

.airport__railway{
    color: #0e0d0d;
    font-weight: 600;
    text-decoration: none;
    font-size: 1rem;
    border: none;
    margin-left: 10px;
    margin-bottom: -70px;
    text-decoration:underline!important;
    /* position: absolute;
    bottom: 0; */
    background-color: white;
}


.airport__railway:hover{
    color: #9c0a0e;
}

.vip__travel{
    color: #0e0d0d;
    font-weight: 600;
    text-decoration: none;
    font-size: 1rem;
    border: none;
    margin-left: 10px;
    text-decoration:underline!important;
    background-color: white;
}

.vip__travel:hover{
    color: #9c0a0e;
}

.long__term-rentals{
    color: #0e0d0d;
    font-weight: 600;
    text-decoration: none;
    font-size: 1rem;
    border: none;
    margin-left: 10px;
    text-decoration:underline!important;
    background-color: white;
}

.long__term-rentals:hover{
    color: #9c0a0e;
}

.outstation__travels{
    color: #0e0d0d;
    font-weight: 600;
    text-decoration: none;
    font-size: 1rem;
    border: none;
    margin-left: 10px;
    text-decoration:underline!important;
    background-color: white;
}

.outstation__travels:hover{
    color: #9c0a0e;
}



/* {------------------------------------------------ Mobile Screen Code ----------------------------------------------} */

@media only screen and (min-width: 300px) and (max-width: 767px)  {
    .cards {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));
        padding: 20px;
        grid-gap:40px;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-left: 1px;
        margin-top: -90px;
    }
}

@media only screen and (min-width:668px) and (max-width: 1023px) {
    .cards {
        grid-template-columns: repeat(auto-fit, minmax(310px, 2fr));
    }
}



