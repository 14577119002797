.ournetwork__container .ournetwork__banner img {
    width: 1465px;
    height: 400px;
    margin-bottom: 100px;
}

.ournetwork__title h1{
    color: black;
    margin-top: -50px;
    margin-left: -50px;
    text-decoration: underline;
}

.ournetwork__citynam {
    margin-bottom: 100px;
}

#customers {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 100px;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }

  #customers td, #customers th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  #customers tr:nth-child(even){background-color: #f2f2f2;}
  
  #customers tr:hover {background-color: #ddd;}
  
  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #04AA6D;
    color: white;
  }

  .button__bookingcar button {
    background: #070707;
    text-decoration: none;
    text-align: center;
    padding: 2px;
    color: #fff;
    border-radius: 1px;
    width: 20%;
    border: none;
    font-size: 20px;
    font-weight: bold;
    margin-left: 590px;
    margin-top: -10px;
    margin-bottom: 100px;
  }

  .button__bookingcar button:hover {
    background-color: darkred;
  }


/*---------------------------------------------mobile code-------------------------------------------*/
@media only screen and (min-width: 300px) and (max-width: 767px)  {
  .ournetwork__container .ournetwork__banner img{
    width: 1400px;
    height: 400px;
    margin-bottom: 100px;
    background-repeat: no-repeat;
    background-size: auto;  
  }

  .ournetwork__title h1{
    color: black;
    margin-top: -50px;
    text-align: center;
    justify-content: center;
    text-decoration: underline;
    margin-left: 10px;
  }

  .ournetwork__citynam {
    margin-bottom: 50px;
  }

  #customers {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 50px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    
  }

  #customers tr {
    float: left!important; width: 50%!important;
  }

  #customers td, #customers th {
    border: 1px solid #ddd;
    padding: 8px 18px;
    display: block;
  }
  
  #customers tr:nth-child(even){background-color: #f2f2f2;}
  
  #customers tr:hover {background-color: #ddd;}
  
  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #04AA6D;
    color: white;
  }

  .button__bookingcar button {
    background: #070707;
    text-decoration: none;
    text-align: center;
    padding: 2px;
    color: #fff;
    border-radius: 1px;
    width: 40%;
    border: none;
    font-size: 20px;
    font-weight: bold;
    margin-left: 90px;
    margin-top: -10px;
    margin-bottom: 100px;
  }

  .button__bookingcar button:hover {
    background-color: darkred;
  }

}

@media only screen and (min-width:668px) and (max-width: 1023px) {
  .button__bookingcar button {
    background: #070707;
    text-decoration: none;
    text-align: center;
    padding: 2px;
    color: #fff;
    border-radius: 1px;
    width: 20%;
    border: none;
    font-size: 20px;
    font-weight: bold;
    margin-left: 300px;
    margin-top: -10px;
    margin-bottom: 100px;
  }

  .button__bookingcar button:hover {
    background-color: darkred;
  }
}
