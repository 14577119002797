.common__section {
    background: 
        url('../assets/all-image/slider/bmw1.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 230px 0px;
}


/* {------------------------------------------------ Mobile Screen Code ----------------------------------------------} */

@media only screen and (min-width: 300px) and (max-width: 667px) {
    .common__section{
        margin-top: -20px;
        height: 100px;
        min-width: 70vh;
        padding: 130px 0px;
    }
}


@media only screen and (min-width:668px) and (max-width: 1023px) {
    .common__section{
        margin-top: -1px;
        min-height: 50vh;
        min-width: 40vh;
        padding: 130x 0px;
    }
}