.App{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.search-box {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    overflow: hidden;
    max-width:350px;
    margin-top: -50px;
    transition: 0.4s;
    margin-bottom: 40px;
}

.search-box input{
    display: block;
    appearance: none;
    outline: none;
    border: none;
    padding: 16px;
    width: 100%;
    transition: 0.4s;
}

.search-box:focus-within{
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
    transform: translateY(-10px);
}

.btn-load{
    width: 100px;
    background-color: #110f29;
    color: #fff;
    cursor: pointer;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    margin-left: 47%;
}

@media only screen and (min-width: 300px) and (max-width: 767px)  {
    .btn-load{
        margin-left: 110px;
    } 
}