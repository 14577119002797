.about__section-content{
    margin-top: 200px;
}

.about__img{
    margin-top: 100px;
}
.about__section-item {
    column-gap: 3rem;
}

.about__section-item p i {
    color: #e3c711;
    font-size: 1.9rem;
}

.section__subtitle-aboutPage{
    color: #9c0a0e;
    font-size: 50px;
    font-weight: 600;
}

.link_tab {
    background: #070707;
    text-decoration: none;
    text-align: center;
    padding: 2px;
    color: #fff;
    border-radius: 1px;
    width: 20%;
    border: none;
    font-size: 20px;
    font-weight: bold;
    margin-left: -470px;
    margin-top: 10px;
}

.link_tab:hover {
    background-color: #9c0a0e;
}

.link_tab a {
    color: #fff;
}

.link_tab a:hover {
    color: #fff;
}

.section__subtitle-aboutPage h2 {
    margin-top: -10px;
    font-family: sans-serif;
    font-weight: 900;
    font-size: 30px;
    color: #9c0a0e;
    margin-left: -1px;
}

.section__description-details {
    text-align: left;
    display:flex;
    flex-direction: column;
    align-items: center;
    color: #070707;
    font-size: 16px;
    font-weight: 600;
    column-gap: normal;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.first__line {
    margin-bottom: -20px;
    margin-left: -35px;
    font-weight: 600;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.first__line b{
    font-size: 20px;
}


/* {------------------------------------------------ Mobile Screen Code ----------------------------------------------} */

@media only screen and (min-width: 300px) and (max-width: 767px)  {
    .about__section-content{
        margin-top: 40px;
    }

    .section__subtitle-aboutPage h2{
        font-size: 15px;
        margin-top: 120px;
    }

    .section__subtitle-aboutPage .section__description-details{
        text-align: justify;
        inline-size: 280px;
    }

    .cities__name{
        text-align: justify;
    }

    .about__img {
      margin-top: -90px;
      position: relative;
      position: relative;
      animation: mymove 5s;
      animation-fill-mode: forwards;
    }

    .about__img img {
        width: 100%;
    }

    .section__subtitle h2{
        font-size: 1.1rem;
    }

    
  .first__line{
        margin-left: 40px;
        inline-size:280px;
        font-size: 15px;
    }

    .first__line .cities__name {
        margin-left: -10px;
    }
  }

  @media only screen and (min-width:668px) and (max-width: 1023px) {
    .about__img {
        margin-top: 250px;
      }
    
    .section__subtitle-aboutPage h2{
        font-size:20px;
        inline-size: 450px;
    }
    .section__description-details{
        margin-right: 50px;
    }

    .first__line{
        margin-left: 180px;
        inline-size:490px;
        font-size: 15px;
    }

    .section__description-details{
        inline-size: 350px;
        text-align: justify;
    }

    .link_tab {
        margin-left: -200px;
        background: #070707;
        text-decoration: none;
        text-align: center;
        padding: 2px;
        color: #fff;
        border-radius: 1px;
        width: 40%;
        border: none;
        font-size: 20px;
        font-weight: bold;
        margin-top: 20px;

    }

  }