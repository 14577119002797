@import url(https://font);
.local__operation-page {
    background-image: url("../assets/all-image/carsimage/localDuty.jpeg");
    height: 500px;
    background-repeat: no-repeat;
    margin-bottom: 20px;
    background-size: 1550px 550px;
}

.local__title {
    text-align: center;
    font-size: 50px;
    margin-bottom: 80px;
    font-family: "Franklin Gothic Medium";
   }
   
 .local__title p{
    font-size: 25px;
    font-family: "Garamond", sans-serif;
    color: #f00e0e;
    margin-left: 50px;
    font-style: italic;
    font-family: bold;
 }


.summary{
   margin-top: -70px;
   border-left: 1px solid black;
   height: 1900px;
   margin-left: 200px;
   border-right: 1px solid black;
   margin-right: 200px;
   margin-bottom: 450px;
   border-top: 1px solid black;
   border-bottom: 1px solid black;
   box-shadow: 0 0 5px;
}

.summary p{
   font-family: "Garamond", sans-serif;
   font-size: 22px;
   text-align: justify;
   margin-left: 43px;
}

.summary span{
   color: #f00e0e;
   font-family: "Garamond", sans-serif;
   font-family: bold;
}

.special__service h1{
   font-size: 26px;
   color: black;
   font-family: "Franklin Gothic Medium";
   justify-content: center;
}

.special__service span{
   text-align: justify;
}

.special__service p{
   font-family: "Garamond", sans-serif;
   text-align: justify;
}

.special__service .special{
   margin-left: 5px;
}

.luxury__rent h1{
   color: black;
   justify-content: center;
}

.luxury__rent span{
   margin-left: 180px;
   font-style: italic;
   font-family: "Garamond", sans-serif;
   font-family: bold;
   text-align: center;
   justify-content: center;
} 

.why__rnk h1{
   font-size: 50px;
   justify-content: center;
   color: #f00e0e;
}

.why__rnk p{
   font-size: 22px;
   text-align: center;
}
 
.Chauffeur__driven h1{
   justify-content: center;
}

.Chauffeur__driven p{
   text-align: center;
}

.gps__enabled h1{
   justify-content: center;
}

.gps__enabled p{
   text-align: center;
}

.Security h1{
   justify-content: center;
}

.security p{
   text-align: center;
}

.Comfort h1{
   justify-content: center;
}

.comfort p{
   text-align: center;
}

.Extensive h1{
   justify-content: center;
}

.Extensive p{
   text-align: center;
}

.support h1{
   justify-content: center;
}

.support p{
   text-align: center;
}


.local__operation-container .accordion{
   max-width: 1300px;
   box-shadow: 0 0 10px rgba(57, 10, 10, 0.2);
   border-radius: 5px;
   overflow: hidden;
   font-family: 'Quicksand', sans-serif;
   margin-top: -400px;
   margin-left: 80px;
   margin-bottom: 100px;
}

.accordion__content p {
   font-family: "Trebuchet MS", Verdana, sans-serif;
   margin-left: 50px;
   inline-size: 1000px;
   text-align: justify;
}

.accordion__label{
   font-size: 20px;
}

/*-----------------------------------mobile code------------------*/
@media only screen and (min-width: 300px) and (max-width: 767px) {
   .local__operation-page {
      background-image: url("../assets/all-image/carsimage/localDuty.jpeg");
      height: 220px;
      background-repeat: no-repeat;
      margin-bottom: 20px;
      background-size: cover;
      width: 100%;
  }

  .local__operation-container .local__title .local__paragraph {
   font-size: 10px;
   font-family: "Garamond", sans-serif;
   color: #f00e0e;
   font-style: italic;
   font-family: bold;
   text-align: center;
   justify-content: center;
   margin-left: 38px;
}

/* .local__definition{
   margin-right: 50px;
} */

.local__operation-container .summary{
   margin-top: -70px;
   border-left: 1px solid black;
   height: 4100px;
   margin-left: 10px;
   border-right: 1px solid black;
   margin-right: 30px;
   margin-bottom: 700px;
   border-top: 1px solid black;
   border-bottom: 1px solid black;
   box-shadow: 0 0 5px;
   inline-size: 340px;
   text-align: justify;
}

.local__operation-container .summary p{
   inline-size: 310px;
   text-align: justify;
   margin-left: 10px;
}
 .local__operation-container .accordion{
   max-width: 1300px;
   box-shadow: 0 0 10px rgba(57, 10, 10, 0.2);
   border-radius: 5px;
   overflow: hidden;
   font-family: 'Quicksand', sans-serif;
   margin-top: -600px;
   margin-left: 10px;
   margin-bottom: 100px;
   margin-right: 10px;
   }

   .local__operation-container .accordion__content p {
      font-family: "Trebuchet MS", Verdana, sans-serif;
      margin-right: 10px;
      inline-size: 240px;
      text-align: justify;
   }

   .luxury__rent span{
      margin-left: 10px;
      font-style: italic;
      font-family: "Garamond", sans-serif;
      font-family: bold;
      text-align: center;
      justify-content: center;
      font-size: 20px;
   } 
   
}


@media only screen and (min-width:668px) and (max-width: 1023px) {
   .local__operation-page {
      background-image: url("../assets/all-image/carsimage/localDuty.jpeg");
      height: 500px;
      background-repeat: no-repeat;
      margin-bottom: 20px;
      background-size: cover;
      width: 100%;
   }

   .local__title p{
      font-size: 15px;
      font-family: "Garamond", sans-serif;
      color: #f00e0e;
      font-style: italic;
      font-family: bold;
      inline-size: 400px;
      text-align: center;
      justify-content: center;
      margin-left: 200px;
   }
   
   .summary{
      margin-top: -70px;
      border-left: 1px solid black;
      height: 5000px;
      margin-left: 10px;
      border-right: 1px solid black;
      margin-right: 10px;
      margin-bottom: 1000px;
      border-top: 1px solid black;
      border-bottom: 1px solid black;
      box-shadow: 0 0 5px;
   }
    .local__operation-container .accordion{
      max-width: 800px;
      box-shadow: 0 0 10px rgba(57, 10, 10, 0.2);
      border-radius: 5px;
      overflow: hidden;
      font-family: 'Quicksand', sans-serif;
      margin-top: -400px;
      margin-left: 10px;
      margin-bottom: 100px;
      margin-right: 10px;
      }
   
      .accordion__content p {
         font-family: "Trebuchet MS", Verdana, sans-serif;
         margin-left: 50px;
         inline-size: 1000px;
         text-align: justify;
      }
   
      .luxury__rent span{
         margin-left: 10px;
         font-style: italic;
         font-family: "Garamond", sans-serif;
         font-family: bold;
         text-align: center;
         justify-content: center;
         font-size: 20px;
      } 

      .special__service h1{
         font-size: 26px;
         color: black;
         font-family: "Franklin Gothic Medium";
         margin-left: 50px;
      }

      
      .special__service span{
         text-align: justify;
      }
      
      .special__service p{
         font-family: "Garamond", sans-serif;
         text-align: justify;
      }
      
      .special__service .special{
         margin-left: 5px;
      }
      
      .luxury__rent h1{
         color: black;
         justify-content: center;
      }
      
      .luxury__rent span{
         margin-left: 70px;
         font-style: italic;
         font-family: "Garamond", sans-serif;
         font-family: bold;
         text-align: center;
         justify-content: center;
      } 
      
      .why__rnk h1{
         font-size: 50px;
         justify-content: center;
         color: #f00e0e;
      }
      
      .why__rnk p{
         font-size: 22px;
         text-align: center;
      }
       
      .Chauffeur__driven h1{
         justify-content: center;
      }
      
      .Chauffeur__driven p{
         text-align: center;
      }
      
      .gps__enabled h1{
         justify-content: center;
      }
      
      .gps__enabled p{
         text-align: center;
      }
      
      .Security h1{
         justify-content: center;
      }
      
      .security p{
         text-align: center;
      }
      
      .Comfort h1{
         justify-content: center;
      }
      
      .comfort p{
         text-align: center;
      }
      
      .Extensive h1{
         justify-content: center;
      }
      
      .Extensive p{
         text-align: center;
      }
      
      .support h1{
         justify-content: center;
      }
      
      .support p{
         text-align: center;
      }
}