.header__top {
    padding: 10px 0px;
    background: #9c0a0e;
    color: #fff;
}

.header__top__left {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    margin-left: -10px;
}

a:link {
    text-decoration: none;
}

.header__top__left span {
    font-size: 0.9rem;
    transition: all 0.3s linear;
    transform: scale(1.4);
}

.header__top__help {
    display: flex;
    align-items: center;
    column-gap: 0.4rem;
}

.header__top__help i {
    background: #9c0a0e;
    color: #fff;
    padding: 2px 4px;
    border-radius: 50px;
    font-size: 1rem;
}

.header__top__right a {
    text-decoration: none;
    color: #fff;
    font-size: 1rem;
}

.header__top__right a:hover {
    color: #fff;
}

/* ====================================== main navbar styles ================================*/

.navbar {
    height: 11vh;
    display: flex;
    justify-content: center;
}

.navbar-logo {
    margin-top: -35px;
    margin-left: 65px;
}

.navbar-logo img {
    width: 110px;
    height: 60px;
    justify-content: center;
}

.nav-items {
    display: flex;
    list-style: none;
    text-align: center;
    justify-content: flex-end;
    margin-right: 5px;
}

.nav-item {
    display: flex;
    align-items: center;
    height: 10vh;
}

.nav-item a {
    text-decoration: none;
    color: black;
    font-size: 1.1rem;
    margin-right: 2rem;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.nav-item a:hover {
    color: #9c0a0e;
}

.nav-btn{
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: #9c0a0e;
    visibility: hidden;
    opacity: 0;
}

.mobile-menu-icon{
    display: none;
} 

/* {------------------------------------------------ Mobile Screen Code ----------------------------------------------} */

@media only screen and (min-width: 300px) and (max-width: 767px)  {
    .sticy{
        position: relative;
    }

    .header__top{
        display: none;
    }

    .navbar-logo{
        display: flex;
        margin-top: -5px;
        margin-left: 10px;
        margin-bottom: -60px;
    }

    .navbar-logo img{
        width: 100px;
        height: 50px;
        margin-bottom: 60px;
    }

    .nav-items{
        display: none;
    }

    .nav-items-mobile{
        position: absolute;
        display: block;
        list-style: none;
        background-color: gainsboro;
        left: 0;
        top: 75px;
        transition: all 0.5 ease-out;
        width: 100%;
        margin-top: -15px;
    }

    .nav-items-mobile:hover{
        color: red;
    }


    .mobile-menu-icon{
        display: block;
        position: absolute;
        font-size: 30px;
        color: black;
        border: none;
        outline: none;
        top: 5px;
        right: 5px;
        background-color: #fff;
    }

    .navbar {
        background: #fff;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;
        display: none;
     }
    }



  @media only screen and (min-width:668px) and (max-width: 1023px) {
    .header__top {
        padding: 10px 0px;
        background: #9c0a0e;
        color: #fff;
    }

    .navbar {
        height: 5vh;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 130px;
    }

    .navbar-logo{
        display: flex;
        margin-top: -115px;
        margin-left: -110px;
        margin-bottom: -60px;
    }

    .navbar-logo img {
        width: 70px;
        height: 50px;
    }

    li{
        margin-top: -40px;
    }
  }
  