.section__title-blog{
    margin-left: 10px;
    margin-top: 10px;
    color: rgb(161, 16, 16);
}
.section__subtitle{
    margin-top: -90px;
    font-size: 30px;
}
.blog__publisher span i {
    color: #0b0b0b;
}

blockquote {
    line-height: 35px;
}

.single__comment img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 2px solid #000d6b;
    object-fit: cover;
}

.replay i {
    color: #f9a826;
}

.replay {
    color: #000d6b;
    font-weight: 600;
    cursor: pointer;
}

.leave__comment-form {
    background-color: #ada4a4;
    border-radius: 10px;
    width: 500px;
}

.leave__comment-form h1{
    font-size: 44px;
    color: rgb(161, 16, 16);
}

.contact__form input {
    width: 400px;
    margin-left: 50px;
}

.comment__btn {
    background: #070707 !important;
    color: #fff !important;
    margin-top: -50px;
    margin-bottom: 10px;
    margin-left: 105px;
    padding: 8px 12px;
    width: 310px;
    border-radius: 5px;
}

.recent__blog-item h6 a {
    text-decoration: none;
    color: #000d6b;
    font-weight: 600;
}

.blog__details .section__title{
    text-align: center;
}

.blog__details .section__description {
    color: #0b0b0b;
    font-size: 20px;
    text-align: justify;
    font-family: "Garamond", sans-serif;
}

.blog__details .section__heading{
    margin-left: -350px;
    font-size: 30px;
    font-family: "Franklin Gothic Medium";
}

.blog__details .section__headingdes {
    font-family: "Garamond", sans-serif;
    text-align: justify;
    font-size: 20px;
}

.blog__details .section__heading1 {
    margin-left: -370px;
    font-size: 30px;
    font-family: "Franklin Gothic Medium";
}

.blog__details .section__headingdes1 {
    font-family: "Garamond", sans-serif;
    text-align: justify;
    font-size: 20px;
}

.blog__details .section__heading2 {
    margin-left: -330px;
    font-size: 30px;
    font-family: "Franklin Gothic Medium";
}

.blog__details .section__headingdes2 {
    font-family: "Garamond", sans-serif;
    text-align: justify;
    font-size: 20px;
}

.blog__details .section__heading3 {
    margin-left: -380px;
    font-size: 30px;
    font-family: "Franklin Gothic Medium";
}

.blog__details .section__headingdes3 {
    font-family: "Garamond", sans-serif;
    text-align: justify;
    font-size: 20px;
}

.blog__details .section__conclusion {
    margin-left: -600px;
    font-size: 30px;
    font-family: "Franklin Gothic Medium";
}

.blog__details .section__headingdes1 {
    font-family: "Garamond", sans-serif;
    text-align: justify;
    font-size: 20px;
}

.blog__details .section__headingsecond {
    margin-left: -600px;
    font-size: 30px;
    font-family: "Franklin Gothic Medium";
}

.blog__details .section__headingdescriptionvalue{
    font-family: "Garamond", sans-serif;
    text-align: justify;
    font-size: 20px;
}

.blog__details .section__headingsecondFour {
    margin-left: -80px;
    font-size: 25px;
    font-family: "Franklin Gothic Medium";
}

.blog__details .section__conclusionsecond{
    margin-left: -650px;
    font-size: 25px;
    font-family: "Franklin Gothic Medium";  
}

.blog__details .section__conDescriptionsecond{
    font-family: "Garamond", sans-serif;
    text-align: justify;
    font-size: 20px;   
}

.blog__details .section__bestcar{
    margin-left: -50px;
    font-size: 25px;
    font-family: "Franklin Gothic Medium";
    color: red;
}

.blog__details .section__describeCarTitle1 {
    margin-left: -500px;
    font-size: 25px;
    font-family: "Franklin Gothic Medium";
}

.blog__details .section__describeCar1 {
    font-family: "Garamond", sans-serif;
    text-align: justify;
    font-size: 20px;   
}

.blog__details .section__describeCarTitle2 {
    margin-left: -430px;
    font-size: 25px;
    font-family: "Franklin Gothic Medium";
}

.blog__details .section__describeCar2 {
    font-family: "Garamond", sans-serif;
    text-align: justify;
    font-size: 20px;   
}

.blog__details .section__describeCarTitle3 {
    margin-left: -350px;
    font-size: 25px;
    font-family: "Franklin Gothic Medium";
}

.blog__details .section__describeCar3 {
    font-family: "Garamond", sans-serif;
    text-align: justify;
    font-size: 20px;   
}

.blog__details .section__describeCarTitle4 {
    margin-left: -430px;
    font-size: 25px;
    font-family: "Franklin Gothic Medium";
}

.blog__details .section__describeCar4 {
    font-family: "Garamond", sans-serif;
    text-align: justify;
    font-size: 20px;   
}

.blog__details .section__describeCarTitle5 {
    margin-left: -540px;
    font-size: 25px;
    font-family: "Franklin Gothic Medium";
}

.blog__details .section__describeCar5 {
    font-family: "Garamond", sans-serif;
    text-align: justify;
    font-size: 20px;   
}

.blog__details .section__describeCarTitle6 {
    margin-left: -600px;
    font-size: 25px;
    font-family: "Franklin Gothic Medium";
}

.blog__details .section__describeCar6 {
    font-family: "Garamond", sans-serif;
    text-align: justify;
    font-size: 20px;   
}

@media only screen and (min-width: 300px) and (max-width: 667px) {
    .blog__details {
        margin-top: -50px;
        width: 300px;
    }

    .blog__details .section__title-blog{
        font-size: 14px;
    }

    .blog__details .section__heading {
        margin-left: -30px;
        font-size: 15px;
    }

    .blog__details .section__heading1 {
        margin-left: 10px;
        font-size: 15px;
    }

    .blog__details .section__heading2 {
        margin-left: 10px;
        font-size: 15px;
    }

    .blog__details .section__heading3 {
        margin-left: 10px;
        font-size: 15px;
    }

    .blog__details .section__heading4 {
        margin-left: 10px;
        font-size: 15px;
    }

    .blog__details .section__heading5 {
        margin-left: 10px;
        font-size: 15px;
    }

    .blog__details .section__conclusion{
        margin-left: 10px;
        font-size: 15px;
    }

    .blog__details .section__headingsecondFour {
        margin-left: 10px;
        font-size: 10px;
    }

    .leave__comment-form h1{
        margin-left: 30px;
    }

    .leave__comment-form{
        width: 100%;
    }

    .leave__comment-form h1{
        font-size: 20px;
    }

    .leave__comment-form input{
        width: 80%;
        margin-left: 30px;
    }

    .comment__btn {
        margin-left: 30px;
        width: 80%;
    }
    .blog__details .section__describeCarTitle1{
        margin-left: 20px;
        font-size: 18px;
    }
    .blog__details .section__describeCarTitle2{
        margin-left: 20px;
        font-size: 18px;
    }
    .blog__details .section__describeCarTitle3{
        margin-left: 20px;
        font-size: 18px;
    }
    .blog__details .section__describeCarTitle4{
        margin-left: 20px;
        font-size: 18px;
    }
    .blog__details .section__describeCar1 {
        text-align: justify;
    }
}


@media only screen and (min-width:668px) and (max-width: 1023px) {
    .blog__details .section__heading {
        margin-left: 10px;
    }

    .blog__details .section__heading1 {
        margin-left: 10px;
    }

    .blog__details .section__heading2 {
        margin-left: 10px;
    }

    .blog__details .section__heading3{
        margin-left: 10px;
    }

    .blog__details .section__heading4 {
        margin-left: 10px;
    }

    .blog__details .section__heading5 {
        margin-left: 10px;
    }

    .blog__details .section__conclusion {
        margin-left: 10px;
    }

    .leave__comment-form h1 {
        margin-left: 30px;
    }
}

