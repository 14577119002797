.mobile__input{
    background-image:linear-gradient(rgb(41, 41, 41), rgb(78, 78, 72));
    margin-top: 60px;
    margin-bottom: 50px;
    position: relative;
    border-radius: 5px;
}

h1{
    text-align: center;
    justify-content: center;
    font-size: 20px;
    font-family: bold;
    color: antiquewhite;
    margin-bottom: 30px;
}

.mobile__input-feild{
    border-radius: 20px;
    margin-left: 100px;  
    margin: 20px;  
}

label{
    color: #fff;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    margin-left: 25px;
}

.mobile__input-feild input{
    border-radius: 5px;
    height: 40px;
    outline: none;
    border: none;
    color: black;
    width: 250px;
    margin-left: 25px;

}

::placeholder{
    margin-left: 20px;
}

.mobile__input-feild-btn{
    padding: 18px 12px;
}

.mobile__input-feild-btn button{
    width: 16.5rem;
    border-radius: 50px;
    outline: none;
    border: none;
    color: #fff;
    background-color: #9c202c;
    padding: 10px;
    margin-left: 20px;
}

.mobile__input-feild-btn button:hover{
    background-color: #ea1429;
}

@media only screen and (min-width: 300px) and (max-width: 767px)  {
    .mobile__input{
        margin-top: 10px;
    }
}






