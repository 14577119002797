.service__item {
    font-size: 2.5rem;
    color: #9c0a0e;
}

.section__title{
    color: #9c0a0e;
    font-weight: 700;
    font-size: 30px;
}

.section__title p{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

.service__item h6 {
    font-size: 1.2rem;
    font-weight: 600;
    transition: .3s;
}

.service__item h6:hover {
    color: #f9a826;
}

/* @media only screen and(max-width: 768px) {
    .service__item h6 {
        font-size: 1.1rem;
    }
} */